<template>
  <v-app :style="{ background: colors[options.theme] }">
    <v-theme-provider
      :dark="options.theme === 'dark'"
      :light="options.theme === 'light'"
    >
      <v-card
        v-if="loading"
        class="d-flex flex-column justify-center align-center flex-grow-1 gap-4"
      >
        <v-progress-circular indeterminate></v-progress-circular>
        <h5 class="text-center">Carregando mapa do evento</h5>
      </v-card>
      <v-card
        v-else-if="error"
        class="d-flex flex-column justify-center align-center flex-grow-1 gap-2 pa-4"
      >
        <h5 class="text-center">Ops! Ocorreu um erro ao carregar o mapa</h5>
        <p class="text-center">
          {{ error.message }}
        </p>
      </v-card>
      <div v-else>
        <table-selector
          v-if="data?.tableMap"
          class="rounded-0 elevation-0"
          :table-map="data.tableMap"
          :party="data.party"
          :loading="loading"
          :width="documentSize.width"
          :height="documentSize.height - 24"
        />
      </div>
    </v-theme-provider>
  </v-app>
</template>

<script>
import SHOP from "@/services/shop";
import TableSelector from "@/components/global/partyTable/TableSelector.vue";

const defaultOptions = {
  theme: {
    default: "light",
    rule: (value) => ["light", "dark"].includes(value),
  },
};

export default {
  components: { TableSelector },
  data() {
    return {
      options: {},
      loading: true,
      error: null,
      documentSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      colors: {
        dark: "#121212",
        light: "#fff",
      },
    };
  },

  methods: {
    async getParty() {
      try {
        this.loading = true;
        debugger;
        let response = await SHOP.party(
          this.$route.params.orgSlug,
          this.$route.params.partyId
        );

        const org = response?.organization;
        if (org?.Platform)
          throw {
            message: "Esse evento não pertence a essa plataforma",
            code: 404,
            status: "error",
          };

        if (!response.tableMap)
          throw {
            message: "Esse evento não possui um mapa",
            code: 404,
            status: "error",
          };

        this.data = response;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    calculateCanvasSize() {
      this.documentSize.width = window.innerWidth;
      this.documentSize.height = window.innerHeight;
    },
  },

  mounted() {
    const query = this.$route.query;
    const options = Object.entries(defaultOptions).reduce(
      (acc, [key, value]) => {
        var value = value.default;
        if (query[key]) {
          if (!value.rule || value.rule(query[key])) value = query[key];
        }

        acc[key] = value;
        return acc;
      },
      {}
    );

    this.options = options;
    this.getParty();
    window.addEventListener("resize", this.calculateCanvasSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateCanvasSize);
  },
  beforeMount() {
    if (window.self === window.top)
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.$route.params.orgSlug,
          partyId: this.$route.params.partyId,
        },
      });
  },
};
</script>
